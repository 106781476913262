import React, { useEffect, useState, useCallback } from 'react';
import Uploader from '../photos/uploadPhotos';
import LoadingSpinner from '../loader';
import './side.css'; // Import CSS file for styling
import { useLocation } from '@reach/router';
import Webcam from 'react-webcam';
import {
  getGroomBrideDetails,
  postImageWeddingQr,
  getMobileUploadedImages,
  getPresignedUrl,
} from '../../actions/weddingQRActions';
import { toast } from 'react-toastify';
import { getLoggedInUser } from '../../actions/authAction';
import { isQRTokenValid } from '../../helpers/tokenUtils';
import { navigate } from 'gatsby';
import ViewPhotos from '../photos/viewPhotos';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import { Tabs, Tab } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CollectionsIcon from '@mui/icons-material/Collections';
import LogoutIcon from '@mui/icons-material/Logout';

const WeddingSide = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const brideId = searchParams.get('brideId');
  const groomId = searchParams.get('groomId');
  const brideName = searchParams.get('brideName');
  const groomName = searchParams.get('groomName');
  const weddingSide = searchParams.get('side');
  const [result, setResult] = useState({});
  const [selectedSide, setSelectedSide] = useState(weddingSide);
  const [mobileImages, setMobileImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSideNodeId, setSelectedSideNodeId] = useState(selectedSide === 'GROOM' ? groomId : brideId);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [resultLoading, setResultLoading] = useState(true); // New state for tracking result loading
  const webcamRef = React.useRef(null);
  const [facingMode, setFacingMode] = useState('user');
  const [tabIndex, setTabIndex] = useState(1); // State for selected tab
  const [camError, setCamError] = useState(false);
  const [cameraPermissionDenied, setCameraPermissionDenied] = useState(false);

  const loggedInUserMobile = getLoggedInUser({ mobile: true });

  useEffect(() => {
    const fetchDetails = async () => {
      setResultLoading(true);
      let token = localStorage.getItem('Token') || null;
      console.log('token inside side ', token);
      if (!token || !isQRTokenValid(token)) {
        navigate(`/weddingQR/login${location.search}`);
        return;
      }

      const nodeId = selectedSide === 'GROOM' ? groomId : brideId;
      try {
        let response = {};
        if (selectedSide === 'BRIDE') {
          response = await getGroomBrideDetails(brideId, 'BRIDE');
        } else {
          response = await getGroomBrideDetails(groomId, 'GROOM');
        }
        console.log('response from getGroomBride ', response);
        if (response?.status) {
          setResult(response);
          setSelectedSideNodeId(nodeId);
          setIsUserRegistered(true);
        } else {
          toast.error(response?.error_message);
          setIsUserRegistered(false);
        }
      } catch (error) {
        console.error('Error fetching Details', error);
        setIsUserRegistered(false);
      } finally {
        setResultLoading(false);
      }

      getImages(loggedInUserMobile, nodeId, selectedSide);
    };

    fetchDetails();
  }, [selectedSide, location.search]);

  const handleSelectSide = (side) => {
    setSelectedSide(side);
    const nodeId = side === 'GROOM' ? groomId : brideId;
    setSelectedSideNodeId(nodeId);
  };

  const handleUploadSuccess = async (url) => {
    const photoUrls = [url];
    try {
      const data = {
        mobile: loggedInUserMobile,
        photo_urls: photoUrls,
      };

      if (selectedSide === 'GROOM') {
        data.groom_node_id = groomId;
      } else {
        data.bride_node_id = brideId;
      }
      const res = await postImageWeddingQr(data);
      if (res?.status) {
        getImages(loggedInUserMobile, selectedSideNodeId, selectedSide);
        toast.success('आपका फ़ोटो सफलतापूर्वक अपलोड हो चुका है।', { autoClose: 4000 });
      }
    } catch (err) {
      console.log('error while uploading photos', err);
    }
  };

  const getImages = async (loggedInUserMobile, selectedSideNodeId, selectedSide) => {
    try {
      const res = await getMobileUploadedImages(loggedInUserMobile, selectedSideNodeId, selectedSide);
      const photoUrls = res?.subevent_photos?.map((photo) => photo.photo_url) || [];
      setMobileImages(photoUrls);
    } catch (error) {
      console.log('error', error);
    }
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const capture = useCallback(async () => {
    setLoading(true);
    const imageSrc = webcamRef.current.getScreenshot();
    console.log('image src: ', imageSrc);
    var blob = dataURItoBlob(imageSrc);
    const file_name = `webcam_${Date.now()}.jpeg`;
    var file = new File([blob], file_name);
    const fileData = { file_name, event_id: result?.event?._id };

    try {
      const presignedUrlResponse = await getPresignedUrl(fileData);
      // Handle the upload success with the presigned URL
      if (presignedUrlResponse.status) {
        await fetch(presignedUrlResponse.url, { method: 'PUT', body: file });
        handleUploadSuccess(presignedUrlResponse.s3_url);
      }
    } catch (error) {
      console.error('Error uploading image', error);
    } finally {
      setLoading(false); // Hide the loading spinner after upload
    }
  }, [webcamRef, selectedSide, groomId, brideId, result]);

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate(`/weddingQR/login${location.search}`);
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Different name according to the samaj
  const isBrowser = typeof window !== 'undefined';
  let hostname = '';
  if (isBrowser) {
    hostname = window.location.hostname;
  }
  let appLink, appName;
  if (hostname.includes('meghwalweb.merasamaj.app')) {
    appLink = 'https://play.google.com/store/apps/details?id=com.meghwalsamaj&hl=en_IN';
    appName = 'मेघवाल समाज';
  } else if (hostname.includes('paliwalweb.merasamaj.app')) {
    appLink = 'https://play.google.com/store/apps/details?id=com.paliwalkutumb&hl=en_IN';
    appName = 'पालीवाल ब्राह्मण समाज';
  }

  const scrollToPhotos = () => {
    // Scroll to the photos section
    const photosSection = document.querySelector('.uploaded-images');
    if (photosSection) {
      photosSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const checkCameraPermission = async () => {
    try {
      const permissionObj = await navigator.permissions.query({ name: 'camera' });
      console.log(permissionObj.state);

      if (permissionObj.state === 'denied' || permissionObj.state === 'prompt') {
        setCameraPermissionDenied(true);
        toast.error(
          'Camera permission is required to capture photos. Please allow camera access in your browser settings.',
        );
      }
      // else if (permissionObj.state === 'prompt') {
      // setCameraPermissionPrompt(true);
      // }
      else {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraPermissionDenied(false);
        setCamError(false);
      }
    } catch (error) {
      console.error('Error checking camera permission', error);
      toast.error('Error checking camera permission. Please try again.');
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      checkCameraPermission();
    }
  }, [tabIndex === 0]);

  const requestCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraPermissionDenied(false);
      setCamError(false);
    } catch (error) {
      console.error('Error requesting camera permission', error);
      toast.error('Camera permission is required to capture photos.');
    }
  };

  return (
    <div className="photo-upload-flow-container">
      <div className="side-selection">
        <h1 className="wedding-header">
          <span style={{ color: '#E8AA42' }}>{groomName}</span>
          <span style={{ color: '#E8AA42', fontSize: '1.5rem', margin: '0 10px' }}>संग</span>
          <span style={{ color: '#E8AA42' }}>{brideName}</span>
        </h1>
        <h2>आप किस पक्ष की फोटो अपलोड कर रहे हैं ?</h2>
        <div className="radio-group">
          <label className="radio-label">
            <input
              type="radio"
              value="bride"
              checked={selectedSide === 'BRIDE'}
              onChange={() => handleSelectSide('BRIDE')}
            />
            <span className="radio-text">वधू पक्ष</span>
          </label>
          <label className="radio-label">
            <input
              type="radio"
              value="groom"
              checked={selectedSide === 'GROOM'}
              onChange={() => handleSelectSide('GROOM')}
            />
            <span className="radio-text">वर पक्ष</span>
          </label>
        </div>
        <a onClick={scrollToPhotos} className="see-all-photos-link">
          आपके सभी फ़ोटो देखें
        </a>
      </div>
      <div className="uploader-section">
        <div className="upload-container">
          {resultLoading ? (
            <LoadingSpinner message="Loading..." />
          ) : isUserRegistered ? (
            <>
              {loading && <LoadingSpinner message="फोटो अपलोड हो रही है..." />}
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                className="custom-tabs"
                TabIndicatorProps={{ children: <span /> }}
              >
                <Tab icon={<PhotoCameraIcon />} label="कैमरा" className="custom-tab" />
                <Tab icon={<CollectionsIcon />} label="गैलरी" className="custom-tab" />
              </Tabs>
              {tabIndex === 0 && (
                <>
                  {cameraPermissionDenied ? (
                    <button onClick={requestCameraPermission} className="request-permission-button">
                      कैमरा अनुमति दें
                    </button>
                  ) : (
                    <>
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        className="webcam"
                        videoConstraints={{ facingMode: facingMode }}
                        onUserMediaError={(error) => {
                          toast.error('Error:' + error.message);
                          setCamError(true);
                          if (error) {
                            setCameraPermissionDenied(true);
                          }
                        }}
                      />
                      <div className="button-row">
                        <button onClick={toggleCamera} className="toggle-camera-button">
                          <FlipCameraIosIcon />
                        </button>
                        <button onClick={capture} className="capture-button">
                          फोटो खींचें
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {tabIndex === 1 && (
                <Uploader
                  weddingId={result?.event?._id}
                  doCompression={true}
                  onUploadSuccess={(url, md5Code) => handleUploadSuccess(url, md5Code)}
                />
              )}
            </>
          ) : (
            <p style={{ color: 'red' }}>
              {selectedSide === 'GROOM' ? 'वर' : 'वधू'} पक्ष ने हमारी ऐप में विवाह को नहीं बनाया है।
            </p>
          )}
          <div className="uploaded-images">
            {mobileImages?.length > 0 ? (
              <ViewPhotos
                photos={mobileImages}
                heading="आपके द्वारा अपलोड की गई तस्वीरें"
                onClose={() => console.log('Close photos')}
                showCloseBtn={false}
                showDeleteBtn={false}
                showMenu={false}
              />
            ) : (
              <div className="no-photos-message">
                <p>आपने कोई फोटो अपलोड नहीं की हैं।</p>
              </div>
            )}
          </div>
          <strong>
            विवाह की सभी तस्वीरें देखने के लिए {appName} ऐप{' '}
            <a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              डाउनलोड करें
            </a>
          </strong>
        </div>
      </div>
      <button className="logout-button" onClick={handleLogout}>
        <span className="logout-icon-span">
          <LogoutIcon />
          <span className="logout-text">लोगआउट</span>
        </span>
      </button>
    </div>
  );
};

export default WeddingSide;
